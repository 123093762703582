import { faAngleDown, faAngleUp, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { Accordion, AccordionContext, Card, useAccordionToggle } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeck } from '../../redux/actions';
import { PatreonStatus } from '../../types';
import './DeckList.scss';

const DeckList = ({ decks, onDeckSelected }) => {
    const user = useSelector((state) => state.account.user);
    const allowPremium = user?.patreon === PatreonStatus.Pledged || user?.permissions.isSupporter;

    const { selectedDeck } = useSelector((state) => ({
        selectedDeck: state.cards.selectedDeck
    }));

    const dispatch = useDispatch();

    const doClick = (event, deck) => {
        dispatch(selectDeck(deck));
        (!deck.premium || allowPremium) && onDeckSelected && onDeckSelected(deck);
    };

    return (
        <div className='deck-list'>
            {decks?.map((d, index) => {
                const idClass = d.listClass;
                const isSelected = selectedDeck === d;
                const cardClasses = classNames('decklist-entry', {
                    'selected-deck': isSelected
                });
                return (
                    <div key={d} className={cardClasses} onClick={(event) => doClick(event, d)}>

                        <div className={`decklist-entry-image ${d.champions[0].id}`}></div>
                        <div>
                            <button className='decklist-title' tabIndex={0}>{d.name}</button>&nbsp;
                            <br />
                            {d.premium && !allowPremium && (
                                <div className='premium-lozenge'>
                                    <FontAwesomeIcon icon={faLock} />
                                    &nbsp;Premium
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

DeckList.displayName = 'DeckList';
export default DeckList;
